.DescriptionTitle {
  padding-top: 50px;
  display: flex;
  justify-content: space-between;
}

.DescriptionTitle-title {
  text-align: center;
  padding: 0 0.5em;
}

.DescriptionTitle-decoration {
  display: none;
}

@media screen and (min-width: 768px) {
  .DescriptionTitle-decoration {
    line-height: 1;
    flex-shrink: 0;
    font-size: 10rem;
  }
}

.DescriptionContent {
  padding: 50px 0;
}

@media screen and (min-width: 768px) {
  .DescriptionContent {
    column-count: 2;
    column-gap: 5rem;
  }
}

.DescriptionContent-socials {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
}

.DescriptionContent-socials > * { flex-basis: 10%; }

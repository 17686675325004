.App {
  display: grid;
}

.App-sparkleWrapper,
.App-content {
  grid-area: 1/1;
}

.App-sparkleWrapper {
  position: relative;
  overflow: hidden;
}

.App-sparklePositioner {
  width: 0;
  height: 0;
  position: absolute;
  top: 50vh;
  left: 50%;
}

.App-sparkleOne,
.App-sparkleTwo,
.App-sparkleThree {
  position: absolute;
  transform: translate(-50%, -50%);
}

.App-sparkleOne {
  left: -54vw;
  top: -45vh;
}

@media screen and (orientation: landscape) {
  .App-sparkleOne {
    left: max(-712px, -40vw);
    top: max(-500px, -40vh);
  }
}


.App-sparkleTwo {
  right: -67vw;
  top: -37vh;
}

@media screen and (orientation: landscape) {
  .App-sparkleTwo {
    right: max(-712px, -50vw);
    top: max(-500px, -35vh);
  }
}

.App-sparkleThree {
  right: -67vw;
  bottom: -43vh;
}

@media screen and (orientation: landscape) {
  .App-sparkleThree {
    right: max(-640px, -42vw);
    bottom: max(-500px, -54vh);
  }
}

.App-description {
  padding: 50px 0;
}
.Marquee {
  overflow: hidden;
  border-color: var(--yellow-base);
  border-style: solid;
  border-width: 10px 0;
  position: relative;
  height: 83px;
  padding: 4px 0;
  font-size: 6rem;
  line-height: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.Marquee-row {
  width: 300%;
  position: relative;
  left: -100%;
  flex-shrink: 1;
  display: flex;
  justify-content: space-between;
}

.Marquee-item {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transform: translateY(-6%);
}